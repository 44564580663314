<template>
  <v-container>
    <h2 class="my-4">{{ $t('users') }}</h2>
    <v-data-table :headers="headers" :items="users" item-key="Id">
      <template v-slot:item.actions="{ item }">
        <v-btn color="red" small @click="deleteUser(item.Id)">{{ $t('remove') }}</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import makeOrgService from '@/services/api/org';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      headers: [
        {
          text: this.$t('firstName'),
          value: 'firstName',
          sortable: true,
        },
        {
          text: this.$t('lastName'),
          value: 'lastName',
          sortable: true,
        },
        {
          text: this.$t('email'),
          value: 'email',
          sortable: true,
        },
        { text: 'Actions', value: 'actions' },
      ],
      users: [],
      org: undefined,
    };
  },
  async mounted() {
    this.getUsers();
  },
  computed: {
    ...mapGetters({
      org: 'user/currentAccount',
    }),
  },
  methods: {
    // CTODO: Test all of this
    getUsers() {
      const orgService = makeOrgService(this.$api);
      orgService
        .getUsers(this.org.uid)
        .then((response) => {
          this.users = response.data.users;
        })
        .catch((error) => {
          this.$swal({
            title: error.response.data.error,
            icon: 'warning',
            showCancelButton: true,
            focusCancel: true,
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('cancel'),
            cancelButtonText: this.$t('remove'),
          });
        });
    },
    removeUser(id) {
      this.$swal({
        title: this.$t('you are sure'),
        text: this.$t('you cannot reverse this action'),
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('cancel'),
        cancelButtonText: this.$t('remove'),
      }).then((result) => {
        if (!result.isConfirmed) {
          const orgService = makeOrgService(this.$api);
          orgService.removeUser({ handle: this.getOrg.handle, memberId: id }).then(
            // CTODO: add this service to remove a user from the org
            () => {
              this.users = this.users.filter((user) => user.Id !== id);
              this.$swal(this.$t('user deleted'), this.$t('user successfully deleted'), this.$t('success'));
            },
            (error) => {
              this.$swal('Error', error.response.data.error, 'error');
            }
          );
        }
      });
    },
  },
};
</script>